import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div class="lds-ripple"><div></div><div></div></div>
      </header>
    </div>
  );
}

export default App;
